<template>
  <div class="service-list">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-if="serviceList && serviceList.length > 0"
        v-model="loading"
        :finished="finished"
        :loading-text="loadingText"
        finished-text="没有更多了"
        @load="getDataList"
      >
        <div
          v-for="item in serviceList"
          :key="item.orderId"
          class="list-item"
          @click="toServiceDetails(item.orderId)"
        >
          <div class="service-time">
            <div class="time-left">
              发起时间：{{ item.afterSaleCeatorTime || "" }}
            </div>
            <div class="left-right">
              {{ serviceStatus[item.afterSaleStatus] }}
            </div>
          </div>
          <div class="order-info">
            <img
              class="product-img"
              :src="
                item.productType == 30
                  ? require('../assets/images/bill.png')
                  : item.productImg || defaultProductImg
              "
              alt=""
            />
            <div class="info-box">
              <div class="product-name one-line-hidden">
                {{ item.productName }}
              </div>
              <div class="product-type one-line-hidden">
                {{ renderAttrName(item.productSpecName, item.productAttrName) }}
              </div>
              <div class="product-type one-line-hidden">
                {{ productType[item.deliveryMode] }}
                {{
                  item.deliveryMode == 10
                    ? `(${item.orderRechargeAccount})`
                    : ""
                }}
              </div>
              <div class="refund-money one-line-hidden">
                退款：￥{{ mathManage.formatMoney(item.afterSalePrice, 2) }}
              </div>
            </div>
          </div>
          <div class="service-tip">
            <span class="tip-status">{{
              serviceStatus[item.afterSaleStatus]
            }}</span>
            <span class="tip-text">{{ renderTip(item) }}</span>
          </div>
          <div class="service-action">
            <span class="btn" @click.stop="toServiceDetails(item.orderId)"
              >查看详情</span
            >
          </div>
        </div>
      </van-list>
      <div v-else class="no-list">
        <div class="no-img">
          <img src="../assets/images/no-list.png" alt="" />
        </div>
        <div class="no-tip">当前暂无售后订单</div>
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import { List, PullRefresh } from "vant";
import { getOrderList } from "@/api/common";
import { serviceStatus, productType } from "@/utils/dictionary";
import mathManage from "@/utils/mathManage";
import { mapState } from "vuex";
export default {
  name: "ServiceList",
  components: {
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
  },
  data() {
    return {
      mathManage,
      productType,
      serviceStatus,
      loading: false,
      loadingText: "加载中..",
      finished: false,
      refreshing: false,
      serviceList: [],
    };
  },
  computed: {
    ...mapState(["defaultProductImg"]),
  },
  created() {
    document.title = "退款/售后";
    this.getList();
  },
  methods: {
    getList() {
      this.pageIndex = 1;
      this.$nextTick(async () => {
        try {
          const res = await getOrderList({
            pageSize: this.pageSize,
            pageIndex: this.pageIndex,
            IsAfterSale: true,
          });
          if (res && res.code == 0) {
            this.serviceList = res.data.list || [];
            if (res.data.total === this.serviceList.length) {
              this.finished = true;
            } else {
              this.finished = false;
            }
            this.pageIndex = 2;
            this.refreshing = false;
            this.loading = false;
          } else {
            this.$toast(res.message);
            this.loading = false;
            this.finished = true;
          }
        } catch (error) {
          this.loading = false;
          this.finished = true;
        }
      });
    },

    // 获取订单列表
    async getDataList(type) {
      try {
        const res = await getOrderList({
          pageSize: this.pageSize,
          pageIndex: this.pageIndex,
          IsAfterSale: true,
        });
        if (res && res.code == 0) {
          this.serviceList = [...this.serviceList, ...(res.data?.list || [])];
          this.$nextTick(() => {
            if (this.serviceList.length < res.data.total) {
              this.loading = false;
              this.pageIndex++;
            } else {
              this.finished = true;
              this.loading = true;
            }
          });
        } else {
          this.$toast(res.message);
          this.loading = false;
          this.finished = true;
        }
      } catch (error) {
        this.loading = false;
        this.finished = true;
      }
    },

    // 下拉刷新
    onRefresh() {
      this.getList();
    },

    renderTip(item) {
      if (!item) return "";
      if (item.afterSaleStatus == 30) {
        return `退款￥${mathManage.formatMoney(item.afterSalePrice, 2)}元`;
      }
      if (item.afterSaleStatus == 40 || item.afterSaleStatus == 50) {
        return "退款申请已关闭";
      }
      if (item.afterSaleStatus == 10 || item.afterSaleStatus == 20) {
        return "退款申请处理中";
      }
      return "";
    },

    renderAttrName(productSpecName, productAttrName) {
      if (!productSpecName && !productAttrName) return "";
      if (!productAttrName) return productSpecName;
      return `${productSpecName};${productAttrName}`;
    },

    toServiceDetails(orderId) {
      this.$router.push(`/mall/serviceDetails/${orderId}`);
    },
  },
};
</script>

<style lang="less" scoped>
.service-list {
  height: 100%;
  padding-bottom: 1rem;
  overflow-y: auto;
  padding: 0.28rem 0.36rem;
  box-sizing: border-box;

  .list-item {
    background: #ffffff;
    border-radius: 0.24rem;
    padding: 0.24rem 0.28rem;
    box-sizing: border-box;
    margin-bottom: 0.3rem;

    .service-time {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .time-left {
        font-size: 0.24rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #9b9b9b;
      }

      .left-right {
        font-size: 0.24rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ff4800;
      }
    }

    .order-info {
      display: flex;
      margin-top: 0.28rem;

      .product-img {
        width: 2.04rem;
        height: 1.26rem;
        border-radius: 0.08rem;
        margin-right: 0.2rem;
      }

      .info-box {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .product-name {
          font-size: 0.26rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
        }

        .product-type {
          font-size: 0.24rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
        }

        .refund-money {
          font-size: 0.28rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }
      }
    }

    .service-tip {
      height: 0.84rem;
      background: #fafafa;
      border-radius: 0.08rem;
      display: flex;
      align-items: center;
      padding: 0 0.4rem;
      box-sizing: border-box;
      margin-top: 0.5rem;

      .tip-status {
        font-size: 0.28rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
      }

      .tip-text {
        font-size: 0.28rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        margin-left: 0.24rem;
      }
    }

    .service-action {
      margin-top: 0.28rem;
      display: flex;
      justify-content: flex-end;

      .btn {
        width: 1.6rem;
        height: 0.6rem;
        border-radius: 0.5rem;
        border: 0.01rem solid #cacaca;
        font-size: 0.24rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .no-list {
    width: 100%;
    height: 100%;
    // background: #f9f9f9;
    display: flex;
    flex-direction: column;
    align-items: center;

    .no-img {
      width: 1.96rem;
      height: 1.96rem;
      border-radius: 50%;
      overflow: hidden;
      background-color: #ccc;
      margin-top: 30%;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .no-tip {
      font-size: 0.24rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      text-align: center;
      margin-top: 0.32rem;
    }
  }
}
</style>
